.chat-container {
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    flex-direction: column;
    /* font-family: Arial, sans-serif; */
}

.intro-container {
    background: white;
    width: 80%;
    max-width: 1000px;
    padding: 32px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-height: 80%;
}

.second-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: #9b9b9b;
}

.imagem {
    width: 50%;
    /* height: 300px; */
    /* background: #e8f5e9; */
    /* background-image: url(''); */
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

.texto {
    width: 50%;
    padding: 5px;
    text-align: left;
}

.texto h1 {
    font-weight: bolder;
    font-size: 2.3rem;
    line-height: 1rem;
}

.texto .highlight {
    color: #54b03a;
    font-weight: bolder;
    font-size: 5.2rem;
    line-height: 4.7rem;
}

.texto p {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.texto h3 {
    font-weight: bolder;
    font-size: 2rem;
    margin-top: 10px;
}

.start-chat-button {
    background-color: #2d8c47;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.1rem;
    height: 48px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.start-chat-button:hover {
    background-color: #236b38;
}

/* messages */
.chat-messages {
    min-height: 300px;
    height: 80%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
}

.chat-message {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.user-message {
    flex-direction: row-reverse;
    text-align: right;
}

.message-text {
    max-width: 70%;
    padding: 10px 14px;
    border-radius: 12px;
    font-size: 1.2rem;
    line-height: 1.6;
}

.user-text {
    background-color: #d1e7dd;
    color: #0f5132;
    border-radius: 12px 12px 0 12px;
}

.other-text {
    background-color: #f1f1f1;
    color: #333;
    border-radius: 12px 12px 12px 0;
    text-align: left;
}

.user-avatar {
    width: 58px;
    /* height: 6%; */
    border: 3px solid #54b03a;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 54px;
    max-height: 54px;
    margin-right: 10px;
    border: 3px solid #54b03a;
    background-color: #d1e7dd;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.chat-input-container {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 8px;
}

.chat-input {
    flex: 1;
    padding: 12px;
    border: 3px solid #dcdcdc;
    border-radius: 8px;
    font-size: 1.1rem;
    outline: none;
}

.chat-input:focus {
    border-color: #54b03a;
}

/* Media Queries para responsividade */
@media (max-width: 1000px) {
    .texto {
        text-align: center;
        padding: 0;
    }

    .chat-container {
        width: 100%;
    }

    .intro-container {
        width: 90%;
        padding: 24px;
    }

    .chat-messages {
        min-height: 200px;
    }

    .chat-input {
        font-size: 1.4rem;
    }

    .start-chat-button {
        font-size: 1.4rem;
    }

    .message-text {
        max-width: 90%;
        font-size: 1.5rem;
    }

    .avatar-wrapper {
        margin-right: 6px;
        max-width: 48px;
        max-height: 48px;
        border-width: 2px;
    }
}

@media (max-width: 600px) {
    .imagem {
        display: none;
    }

    .texto {
        width: 100%;
    }

    .texto h1 {
        font-size: 2rem;
    }

    .texto .highlight {
        font-size: 4rem;
    }

    .texto p {
        font-size: 1.3rem;
    }

    .texto h3 {
        font-size: 1.8rem;
    }

    .chat-input {
        font-size: 1.4rem;
    }

    .start-chat-button {
        font-size: 1.4rem;
    }

    .message-text {
        max-width: 90%;
        font-size: 1.5rem;
    }

    .start-chat-button {
        font-size: 1.2rem;
    }

    .message-text {
        font-size: 1.3rem;
    }

    .avatar-wrapper {
        margin-right: 2px;
        max-width: 42px;
        max-height: 42px;
        border-width: 1px;
    }

    .intro-container {
        padding: 16px;
    }
}